* {
  color: #FFFFFF;
  font-family: 'K2D', sans-serif;
}

.app-blue {
  background: #26163B;
}

.bg-1 {
  background: #181818;
}

.bg-2 {
  background: #1B163B;
}

.bg-3 {
  background: #3D3282;
}

.bg-4 {
  background: #2B1F39
}

.bg-5 {
  /* background: #411EA6; */
  background: #20117C;
}

.bg-6 {
  background: #40275F;
}

.bg-7 {
  background: #68419B;
}

.bg-8 {
  background: #411EA6;
}

.bg-9 {
  background: #3E2F9A;
}

.bg-10 {
  background: #161F3B;
}

.bg-gray {
  background: #242424;
}

.bg-gray2 {
  background: #434343;
}

.bg-gray3 {
  background: #474747;
}

.bg-gray4 {
  background: #353535;
}

.bg-green {
  background: #48B759
}

.bg-yellow {
  /* background: #F5E657; */
  background: #B7AC48;
}

.bg-gold {
  background: #9C8639;
}

.bg-red {
  background: #FF4141;
}

.bg-orange {
  background: #FF9C41;
}

.bg-purple {
  background: #7E69FF;
}

.bg-cyan {
  background: #0BC3CF;
}

.text-3 {
  color: #3D3282
}

.text-8 {
  color: #3E61DC;
}

.text-gold {
  color: #9C8639;
}

.text-green {
  color: #0BCF89
}

.text-yellow {
  color: #B7AC48;
}

.text-purple {
  color: #7E69FF;
}

.text-red {
  color: #FF4B4B;
}

.text-cyan {
  color: #0BC3CF;
}


.opacAnimation {
  animation: animate 500ms ease-in-out;
}

@keyframes animate {
  0% {
    opacity: 0.5;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.opacAnimation2 {
  animation: animate2 200ms ease-in-out;
}

@keyframes animate2 {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}


body::-webkit-scrollbar {
  display: none
}

.scroll-hidden::-webkit-scrollbar {
  display: none
}

/* just for custom scrollbar*/
::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #411EA6;
  border: 0px none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #411EA6;
}

::-webkit-scrollbar-thumb:active {
  background: #411EA6;
}

::-webkit-scrollbar-track {
  background: #2B1F39;
  border: 0px none #411EA6;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #2B1F39;
}

::-webkit-scrollbar-track:active {
  background: #2B1F39;
}

::-webkit-scrollbar-corner {
  background: transparent;
}


button:disabled {
  background: rgba(75, 85, 99, 1);
}

button {
  -webkit-appearance: button !important;
  appearance: button !important;
}

.bg-opacity-70 {
  opacity: 0.7 !important;
}


@media (max-width:600px) {
  .landing .login {
    display: none;
  }

  .landing .Authorize,
  .landing .along_the_way,
  .landing .planted {
    margin: auto;
  }

  .landing .byrdsData {
    flex-direction: column;
    text-align: center;
  }
}